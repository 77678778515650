






















import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import BackDashboardButton from '@/components/BackDashboardButton.vue'

@Component({
  components: {
    BackDashboardButton
  }
})
export default class UpdateMyInfoSuccess extends Vue {
  fields = [
    {
      name: this.$t('updateMyInfo.field.currentEmail'),
      icon: 'email',
      value: ''
    },
    {
      name: this.$t('updateMyInfo.field.enterNewEmail'),
      icon: 'email',
      value: ''
    }
  ]

  updateEmail() {
    this.$router.replace({ name: constants.routeNames.UPDATE_MY_INFO_SUCCESS })
  }
}
