









import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import { Auth } from '@/services/Auth'

@Component
export default class BackDashboard extends Vue {
  get text(): string {
    if (Auth.loggedInAs('otp')) {
      return this.$t('backDashboardButton.dashboard').toString()
    } else {
      return this.$t('backDashboardButton.landing').toString()
    }
  }

  goHome() {
    if (Auth.loggedInAs('otp')) {
      this.$router.replace({ name: constants.routeNames.DASHBOARD })
    } else {
      this.$router.replace({ name: constants.routeNames.HOME })
    }
  }
}
